import { API_TEMPLATE } from 'src/libs/constant'
import { post, get, put } from 'src/libs/query'

const calendarConfigApi = {
  addTimeSlot({ startTime, endTime }) {
    return post(`${API_TEMPLATE.CALENDAR_ADD_TIME_SLOT}?startTime=${startTime}&endTime=${endTime}`)
  },
  findAllTimeSlot() {
    return get(`${API_TEMPLATE.CALENDAR_FIND_ALL_TIME_SLOT}`)
  },
  findAllTimeSlotPublished() {
    return get(`${API_TEMPLATE.CALENDAR_FIND_ALL_TIME_SLOT_PUBLISHED}`)
  },
  changeStatusTimeSlot(id) {
    return post(`${API_TEMPLATE.CALENDAR_CHANGE_STATUS_TIME_SLOT}?id=${id}`)
  },
  addClassScheduleTimeSlot(payload) {
    return post(`${API_TEMPLATE.CALENDAR_ADD_CLASS_SCHEDULE_TIME_SLOT}`, payload)
  },
  getClassSchedule(scheduleType) {
    return post(
      `${API_TEMPLATE.CALENDAR_GET_CLASS_SCHEDULE_BY_SCHEDULE_TYPE}?scheduleType=${scheduleType}`,
    )
  },
  getClassScheduleByStudentId (params) {
    return post(
      `${API_TEMPLATE.CALENDAR_GET_CLASS_SCHEDULE_BY_SCHEDULE_TYPE}`, {}, { params }
    )
  },
  hourLearning(type) {
    return get(`${API_TEMPLATE.CALENDAR_HOUR_LEARNING_BY_TYPE}?scheduleType=${type}`)
  },
  timeLearning({ type, studyProgramId }) {
    return post(`${API_TEMPLATE.CALENDAR_HOUR_LEARNING}`, null, {
      params: { scheduleType: type, studyProgramId },
    })
  },
  modifyScheduleHot({ payload, scheduleTypes }) {
    return put(`${API_TEMPLATE.MODIFY_SCHEDULE_HOT}`, payload, {
      params: { scheduleTypes: scheduleTypes.join(',') }
    })
  },
  teacherSchedule(payload) {
    return post(`${API_TEMPLATE.CALENDAR_CLASS_TEACHER_SCHEDULE}`, payload)
  },

  getListAbsenceOfTeacher ({ startDay, endDay, teacherId }) {
    return get(
      `${API_TEMPLATE.SCHEDULE_ABSENCE_OF_TEACHER}?teacherId=${teacherId}&from=${startDay}&to=${endDay}`,
    )
  },

  getAbsenceSummaryOfTeacher ({ time, teacherId }) {
    return get(`${API_TEMPLATE.SUMMARY_ABSENCE_OF_TEACHER}?month=${time}&teacherId=${teacherId}`)
  },

  createScheduleAbsence (payload) {
    return post(`${API_TEMPLATE.CREATE_SCHEDULE_ABSENCE}`, payload)
  },
  updateScheduleAbsence (payload) {
    return post(`${API_TEMPLATE.UPDATE_SCHEDULE_ABSENCE}`, payload)
  },

  filterListAbsences(payload, queryString) {
    return post(`${API_TEMPLATE.FILTER_LIST_ABSENCES}`, {
      ...payload,
      sortOrders: queryString?.sortOrders || [],
    })
  },

  getAbsenceDetailById (id) {
    return get(`${API_TEMPLATE.GET_ABSENCE_DETAIL_BY_ID}?id=${id}`)
  },

  cancelAbsenceSession (id) {
    return post(`${API_TEMPLATE.CANCEL_ABSENCE_SESSION}?id=${id}`)
  },

  updateAbsence (payload) {
    return post(`${API_TEMPLATE.UPDATE_ABSENCE_SESSION}`, payload)
  },

  exportGGSheetListAbsenceTeacher({ payload, sheetId, sheetName }) {
    return post(
      `${API_TEMPLATE.EXPORT_GOOGLE_SHEET_LIST_ABSENCE}?sheetId=${sheetId}&sheetName=${sheetName}`,
      payload,
    )
  },
  exportGGSheetListCancel({ payload, sheetId, sheetName }) {
    return post(
      `${API_TEMPLATE.EXPORT_GOOGLE_SHEET_LIST_CANCEL}?sheetId=${sheetId}&sheetName=${sheetName}`,
      payload,
    )
  },

  updateCancelRegister(payload) {
    return post(`${API_TEMPLATE.CALENDAR_CLASS_CANCEL_SCHEDULE}`, payload)
  },
  cancelTeacherScheduleFilter({ criteria, page, size }, queryString) {
    const path = API_TEMPLATE.CALENDAR_CLASS_CANCEL_SCHEDULE_FILTER
    return post(path, { criteria, page, size, sortOrders: queryString?.sortOrders })
  },
  teacherUrgentCancel (payload) {
    return post(`${API_TEMPLATE.CALENDAR_CLASS_TEACHER_URGENT_CANCEL}`, payload)
  },

  filterListCancelHomeroomClass ({ criteria, page, size }, queryString) {
    const path = API_TEMPLATE.FILTER_LIST_CANCEL_HOMEROOM_CLASS
    return post(path, { criteria, page, size, sortOrders: queryString?.sortOrders })
  },
  getListCancelHomeroomClass(teacherId) {
    return get(`${API_TEMPLATE.GET_LIST_CANCEL_HOMEROOM_CLASS}?teacherId=${teacherId}`)
  },
  getListReason() {
    return get(`${API_TEMPLATE.GET_LIST_REASON}`)
  },
  createCancelHomeroomClass (payload) {
    return post(`${API_TEMPLATE.CREATE_SCHEDULE_HOMEROOM_CLASS}`, payload)
  },
  updateCancelClassTeacher (payload) {
    return post(`${API_TEMPLATE.UPDATE_SCHEDULE_HOMEROOM_CLASS}`, payload)
  },
  updateListCancelRegisterMultiple(payload) {
    return post(API_TEMPLATE.UPDATE_LIST_CANCEL_REGISTER_MULTIPLE, payload)
  },

  // api support quit job
  createSupportQuitJob (payload) {
    return post(`${API_TEMPLATE.CREATE_SUPPORT_QUIT_JOB}`, payload)
  },
  filterListRequestQuitJob ({ criteria, page, size }, queryString) {
    const path = API_TEMPLATE.FILTER_LIST_REQUEST_QUIT_JOB
    return post(path, { criteria, page, size, sortOrders: queryString?.sortOrders })
  },
  confirmSupportQuitJob (payload) {
    return post(`${API_TEMPLATE.CONFIRM_SUPPORT_QUIT_JOB}`, payload)
  },
  getDetailRequestQuitJob (id) {
    return get(`${API_TEMPLATE.GET_DETAIL_QUIT_JOB}?id=${id}`)
  },
  getDateConfig () {
    return get(`${API_TEMPLATE.GET_DATE_CONFIG_QUIT_JOB}`)
  },
  getBackToWorkOptionQuitJob (type) {
    return get(`${API_TEMPLATE.GET_BACK_TO_WORK_OPTIONS_QUIT_JOB}?type=${type}`)
  },
  exportGGSheetListQuitJob({ payload, sheetId, sheetName }) {
    return post(
      `${API_TEMPLATE.EXPORT_GOOGLE_SHEET_LIST_QUIT_JOB}?sheetId=${sheetId}&sheetName=${sheetName}`,
      payload,
    )
  },
  exportGGSheetChangeTeacherQuitJob({ payload, sheetId, sheetName }) {
    return post(
      `${API_TEMPLATE.EXPORT_GOOGLE_SHEET_CHANGE_TEACHER_QUIT_JOB}?sheetId=${sheetId}&sheetName=${sheetName}`,
      payload,
    )
  },
  teacherScheduleRateRegister({ criteria, page, size }, queryString){
    
    let path = `${API_TEMPLATE.TEACHER_SCHEDULE_RATE_REGISTER}?summaryType=${queryString?.summaryType}`
    return post(path, { criteria, page, size, sortOrders: queryString?.sortOrders })
  },
  teacherScheduleRateRegisterDetail({ criteria, scheduleDate, classTimeSlotId, scheduleType }){
    
    let path = `${API_TEMPLATE.TEACHER_SCHEDULE_RATE_REGISTER_DETAIL}?scheduleDate=${scheduleDate}&classTimeSlotId=${classTimeSlotId}&scheduleType=${scheduleType}`
    return post(path, { criteria })
  }
}

export default calendarConfigApi
